import { FC } from 'react'

import styles from './GuaranteePolicy.module.scss'

import { GuaranteePolicy as GuaranteePolicyModel } from 'src/core/Shared/domain/Policies.model'

import { getDetails } from './GuaranteePolicy.model'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { ReservationGuaranteeTimeline } from 'src/core/Reservation/domain/Reservation.model'
import { getGuaranteeTitle } from 'src/ui/i18n/getGuaranteeTitle'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { BulletLine } from 'src/ui/views/_components/BulletLine'
import { useTargetExperiment } from 'src/ui/views/_components/TargetExperiment'
import { Text } from 'src/ui/components'

interface Props {
  guarantee: GuaranteePolicyModel
  timeline: ReservationGuaranteeTimeline
  className?: string
  hasToShowPrices?: boolean
  hasIncludedExtras?: boolean
}

export const GuaranteePolicy: FC<Props> = ({
  guarantee,
  className,
  timeline,
  hasToShowPrices = true,
  hasIncludedExtras = false,
}) => {
  const { trans } = useTrans(['common'])
  const { variantToShow: variantToShowScroll04 } = useTargetExperiment(
    process.env.NEXT_PUBLIC_SCROLL04_MBOX,
  )

  const { formatPrice } = usePriceFormatter()

  const title = getGuaranteeTitle(guarantee, timeline, trans)
  const details = getDetails(
    guarantee,
    timeline,
    trans,
    formatPrice,
    hasToShowPrices,
    hasIncludedExtras,
  )

  return (
    <Flex direction="column" className={className}>
      {variantToShowScroll04 === 'B' ? (
        <Text as="p" fontStyle="m-700" className="mb-xs">
          {title}
        </Text>
      ) : (
        <BulletLine className="mb-xs" fontStyle="m-700" text={title} />
      )}
      <ul>
        {details.map((detail, index) => (
          <li key={index} className={styles.guaranteePolicyItem}>
            {detail}
          </li>
        ))}
      </ul>
    </Flex>
  )
}
