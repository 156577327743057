import { FC } from 'react'

interface Props {
  color: string
}

export const RoomIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M21 21V17.4C21 16.7372 20.4627 16.2 19.8 16.2H4.2C3.53726 16.2 3 16.7372 3 17.4V21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.8H21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6 13.8H5.39995C4.73721 13.8 4.19995 14.3373 4.19995 15V16.2H19.8V15C19.8 14.3373 19.2627 13.8 18.6 13.8Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.59998 11.3999H7.19998C6.8686 11.3999 6.59998 11.6685 6.59998 11.9999V13.7999H10.2V11.9999C10.2 11.6685 9.93135 11.3999 9.59998 11.3999ZM16.8 11.3999H14.4C14.0686 11.3999 13.8 11.6685 13.8 11.9999V13.7999H17.4V11.9999C17.4 11.6685 17.1313 11.3999 16.8 11.3999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.2 4.19995C3.53726 4.19995 3 4.73721 3 5.39995V12.6M21 12.6V5.39995C21 4.73721 20.4627 4.19995 19.8 4.19995"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3672 7.79995H6.2328C6.03989 7.80006 5.8587 7.70742 5.74585 7.55098C5.63299 7.39453 5.60224 7.19337 5.6632 7.01035L6.4632 4.61035C6.54507 4.36531 6.77444 4.20005 7.0328 4.19995H8.5672C8.82555 4.20005 9.05492 4.36531 9.1368 4.61035L9.9368 7.01035C9.99775 7.19337 9.967 7.39453 9.85414 7.55098C9.74129 7.70742 9.5601 7.80006 9.3672 7.79995Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.80005 4.2V3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6328 7.79995H17.7672C17.9601 7.80006 18.1413 7.70742 18.2542 7.55098C18.367 7.39453 18.3978 7.19337 18.3368 7.01035L17.5368 4.61035C17.4549 4.36531 17.2256 4.20005 16.9672 4.19995H15.4328C15.1745 4.20005 14.9451 4.36531 14.8632 4.61035L14.0632 7.01035C14.0023 7.19337 14.033 7.39453 14.1459 7.55098C14.2587 7.70742 14.4399 7.80006 14.6328 7.79995Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2 4.2V3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4 4.19995H12.6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default RoomIcon
