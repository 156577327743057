import {
  CancellationPolicy,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'
import { calculateBody } from 'src/ui/views/_components/CancellationPolicy/calculateBody'
import { calculateDetails } from 'src/ui/views/_components/CancellationPolicy/calculateDetails'
import { Translator } from 'src/ui/hooks/useTrans'
import { Price } from 'src/core/Shared/domain/Price.model'
import { FormatOptions } from 'src/ui/hooks/usePriceFormatter'

const TRANSLATE_CONTEXT_KEY = 'cancellation_policy'

export const getBody = (
  cancellation: CancellationPolicy | CancellationPolicy,
  trans: Translator,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
): string => {
  const { token, options } = calculateBody(
    cancellation,
    formatPrice,
    hasToShowPrices,
  )
  return trans(`${TRANSLATE_CONTEXT_KEY}_body_${token}`, options)
}

type Details = {
  beforeDeadline: string
  afterDeadline: string
  afterCheckIn: string
}

export const getDetails = (
  cancellation: CancellationPolicy,
  guarantee: GuaranteePolicy,
  trans: Translator,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
): Details | undefined => {
  const details = calculateDetails(
    cancellation,
    guarantee,
    formatPrice,
    hasToShowPrices,
  )
  if (!details) return undefined

  const { beforeDeadline, afterDeadline } = details
  const { token: beforeToken, options: beforeOptions } = beforeDeadline
  const { token: afterToken, options: afterOptions } = afterDeadline

  return {
    beforeDeadline: trans(
      `${TRANSLATE_CONTEXT_KEY}_before_${beforeToken}`,
      beforeOptions,
    ),
    afterDeadline: trans(
      `${TRANSLATE_CONTEXT_KEY}_after_${afterToken}`,
      afterOptions,
    ),
    afterCheckIn: trans(
      `${TRANSLATE_CONTEXT_KEY}_after_${afterToken}`,
      afterOptions,
    ),
  }
}
