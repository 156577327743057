import { container } from 'src/core/Shared/_di'
import { sendStartAnalyticsEvent } from './sendStartAnalyticsEvent'

export function sendExperimentEvent({
  mbox,
  variant,
  eventName,
  experimentName,
}: {
  mbox: string
  variant?: string
  eventName: string
  experimentName: string
}) {
  sendStartAnalyticsEvent({ mbox, variant, eventName, experimentName })
  targetTrackEvent(`${mbox}-${eventName}`)
}

function targetTrackEvent(mbox: string) {
  container.resolve('logger').info('Target Experiment :: trackEvent', { mbox })
  window.adobe?.target?.trackEvent({ mbox })
}
