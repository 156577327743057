import {
  CancellationPolicy,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'
import { Price } from 'src/core/Shared/domain/Price.model'
import { FormatOptions } from 'src/ui/hooks/usePriceFormatter'

type Details = {
  beforeDeadline: {
    token: 'no-deposit' | 'refund'
    options?: {
      refund: string
    }
  }
  afterDeadline: {
    token:
      | 'no-deposit-percentage'
      | 'no-deposit-days'
      | 'charge-percentage'
      | 'charge-days'
      | 'refund-percentage'
      | 'refund-days'
      | 'relative-percentage'
      | 'relative-days'
    options?: {
      total?: string
      calculated?: string
      percentage?: number
      days?: number
    }
  }
}

export const calculateDetails = (
  cancellation: CancellationPolicy,
  guarantee: GuaranteePolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
  hasToShowPrices: boolean,
): Details | undefined => {
  if (!cancellation.flexible) {
    return undefined
  }

  const hasFreeCancellation = cancellation.penalty.value === 0
  if (hasFreeCancellation) {
    return undefined
  }

  if (hasToShowPrices) {
    const reservationCancellation = cancellation as CancellationPolicy

    return {
      beforeDeadline: {
        token: calculateBeforeDeadlineToken(guarantee),
        options: calculateBeforeDeadlineOptions(guarantee, formatPrice),
      },
      afterDeadline: {
        token: calculateAfterDeadlineToken(reservationCancellation, guarantee),
        options: calculateAfterDeadlineOptions(
          reservationCancellation,
          formatPrice,
        ),
      },
    }
  }
}

const calculateBeforeDeadlineToken = (
  guarantee: GuaranteePolicy,
): Details['beforeDeadline']['token'] =>
  guarantee.prepay ? 'refund' : 'no-deposit'

const calculateBeforeDeadlineOptions = (
  guarantee: GuaranteePolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
): Details['beforeDeadline']['options'] =>
  guarantee.prepay
    ? {
        refund: formatPrice(guarantee.deposit),
      }
    : undefined

const calculateAfterDeadlineToken = (
  cancellation: CancellationPolicy | CancellationPolicy,
  guarantee: GuaranteePolicy,
): Details['afterDeadline']['token'] => {
  const penaltyType = cancellation.relative.penalty.type

  if (!guarantee.prepay) {
    return `no-deposit-${penaltyType}`
  }

  const reservationCancellation = cancellation as CancellationPolicy

  if (reservationCancellation.calculatedCharge.afterDeadline.value > 0) {
    return `charge-${penaltyType}`
  }

  return `refund-${penaltyType}`
}

const calculateAfterDeadlineOptions = (
  cancellation: CancellationPolicy | CancellationPolicy,
  formatPrice: (price: Price, options?: FormatOptions) => string,
): Details['afterDeadline']['options'] => {
  const penaltyType = cancellation.relative.penalty.type
  const penaltyValue = cancellation.relative.penalty.value

  const reservationCancellation = cancellation as CancellationPolicy

  return {
    total: formatPrice(cancellation.penalty),
    calculated: formatPrice({
      ...reservationCancellation.calculatedCharge.afterDeadline,
      value: Math.abs(
        reservationCancellation.calculatedCharge.afterDeadline.value,
      ),
    }),
    [penaltyType]: penaltyValue,
  }
}
