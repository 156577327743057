import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { ContentItem, OfferItem } from './TargetExperiment.model'
import { container } from 'src/core/Shared/_di'

let targetOffersPromises: Record<string, Promise<ContentItem | undefined>> = {}

export function resetTargetOffersCache() {
  targetOffersPromises = {}
}

export async function getTargetOffer(
  mbox: string,
): Promise<ContentItem | undefined> {
  if (isDefined(targetOffersPromises[mbox])) {
    return targetOffersPromises[mbox]
  }
  return (targetOffersPromises[mbox] = executeGetTargetOffer(mbox))
}

async function executeGetTargetOffer(
  mbox: string,
): Promise<ContentItem | undefined> {
  return new Promise((resolve, reject) => {
    if (
      isDefined(process.env.NEXT_PUBLIC_TARGET_EXPERIMENT) &&
      process.env.NEXT_PUBLIC_TARGET_EXPERIMENT.length > 0
    ) {
      const experimentCollection =
        process.env.NEXT_PUBLIC_TARGET_EXPERIMENT.split(',')

      for (const experiment of experimentCollection) {
        const [envMbox, envVariant] = experiment.split('|')
        if (mbox === envMbox) {
          return resolve({ variant: envVariant, testName: `FAKE-${envMbox}` })
        }
      }
    }

    if (isUndefined(window.adobe)) {
      container.resolve('logger').warning(`window.adobe is undefined (${mbox})`)
      return resolve(undefined)
    }

    window.adobe.target.getOffer({
      mbox,
      success: function (offers: OfferItem[]) {
        if (offers.length === 0) {
          return resolve(undefined)
        }

        const content = offers[0].content[0]
        container
          .resolve('logger')
          .info('Target experiment :: offer', { mbox, content: content })
        return resolve(content)
      },
      error: function (status: unknown, error: unknown) {
        reject({ status, error })
      },
    })
  })
}
