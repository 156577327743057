import { FC } from 'react'

interface Props {
  color: string
}

export const PeopleIcon: FC<Props> = ({ color }) => (
  <>
    <circle
      cx="7.29069"
      cy="4.66045"
      r="2.86047"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2907 8.95117C4.92101 8.95117 3 10.7441 3 12.9558V15.6256H4.43023L5.14535 22.3H7.2907"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.29071 8.95117C9.6604 8.95117 11.5814 10.7441 11.5814 12.9558V15.6256H10.1512L9.43606 22.3H7.29071"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="17.3023"
      cy="9.42791"
      rx="2.38372"
      ry="2.38372"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3023 13.2418C15.1959 13.2418 13.4884 14.9316 13.4884 17.016V17.7709H15.014L15.7767 22.3H17.3023"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3023 13.2418C19.4087 13.2418 21.1163 14.9316 21.1163 17.016V17.7709H19.5907L18.8279 22.3H17.3023"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default PeopleIcon
