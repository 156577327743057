import { FC, ReactNode } from 'react'
import {
  TargetExperimentConfig,
  useTargetExperiment,
} from 'src/ui/views/_components/TargetExperiment/useTargetExperiment'

interface ChildrenFunctionProps {
  children: (state: {
    isLoading: boolean
    isEnabled: boolean
    variantToShow: string | undefined
    sendStartAnalyticsEvent: () => void
    sendExperimentEvent: (eventName: string) => void
  }) => any
  mbox: string
  config?: TargetExperimentConfig
}

interface ChildrenNodeProps {
  children: ReactNode
  mbox: string | undefined
  variant: string
  config?: TargetExperimentConfig
}

type Props = ChildrenFunctionProps | ChildrenNodeProps

export const TargetExperiment: FC<Props> = props => {
  const {
    isEnabled,
    isLoading,
    variantToShow,
    sendStartAnalyticsEvent,
    sendExperimentEvent,
  } = useTargetExperiment(props.mbox, props.config)
  if (typeof props.children === 'function') {
    return props.children({
      isLoading,
      isEnabled,
      variantToShow,
      sendExperimentEvent,
      sendStartAnalyticsEvent,
    })
  } else {
    if (!('variant' in props)) return null
    return variantToShow === props.variant ? props.children : null
  }
}
