import { FC } from 'react'
import { Text, TextColor } from 'src/ui/components'
import styles from './BulletLine.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { FontType } from 'src/ui/styles/settings'

interface Props {
  text: string
  fontStyle?:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
  fontColor?: TextColor
  bulletColor?: TextColor
  className?: string
  'data-testid'?: string
}

export const BulletLine: FC<Props> = ({
  text,
  fontStyle = 'm-700',
  fontColor = 'dark',
  bulletColor = 'primary',
  className,
  'data-testid': testId,
}) => {
  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={classNames(styles[`color-${bulletColor}`], styles.bullet)}
      />
      <Text fontStyle={fontStyle} color={fontColor} data-testid={testId}>
        {text}
      </Text>
    </div>
  )
}
