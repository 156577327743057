import { FC, useState } from 'react'

import styles from './CancellationPolicy.module.scss'
import { classNames } from 'src/ui/utils/classnames'

import { Divider, Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'

import {
  CancellationPolicy as CancellationPolicyModel,
  GuaranteePolicy,
} from 'src/core/Shared/domain/Policies.model'

import {
  getBody,
  getDetails,
} from 'src/ui/views/_components/CancellationPolicy/CancellationPolicy.model'
import { useTrans } from 'src/ui/hooks/useTrans'
import { BreakdownButton } from 'src/ui/views/_components/BreakdownButton'
import { getCancellationTitle } from 'src/ui/i18n/getCancellationTitle'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { BulletLine } from '../BulletLine'
import { useTargetExperiment } from 'src/ui/views/_components/TargetExperiment'

interface Props {
  cancellation: CancellationPolicyModel
  guarantee: GuaranteePolicy
  className?: string
  forceExpandMoreInfo?: boolean
  hasToShowPrices?: boolean
}

export const CancellationPolicy: FC<Props> = ({
  cancellation,
  guarantee,
  className,
  forceExpandMoreInfo = false,
  hasToShowPrices = true,
}) => {
  const { trans } = useTrans(['common'])
  const { variantToShow: variantToShowScroll04 } = useTargetExperiment(
    process.env.NEXT_PUBLIC_SCROLL04_MBOX,
  )

  const { formatPrice } = usePriceFormatter()

  const [showDetails, setShowDetails] = useState<boolean>(false)

  const title = getCancellationTitle(cancellation, trans)
  const body = getBody(cancellation, trans, formatPrice, hasToShowPrices)
  const details = getDetails(
    cancellation,
    guarantee,
    trans,
    formatPrice,
    hasToShowPrices,
  )
  const deadline = isDefined(cancellation.deadline)
    ? cancellation.deadline.format('D MMM YYYY')
    : undefined

  const isHighlighted = cancellation.cancellationType === 'free'

  return (
    <div className={className}>
      {variantToShowScroll04 === 'B' ? (
        <Text
          as="p"
          fontStyle="m-700"
          className="mb-xs"
          color={isHighlighted ? 'support-success' : 'dark'}
        >
          {title}
        </Text>
      ) : (
        <BulletLine
          bulletColor="primary"
          className="mb-xs"
          fontColor={isHighlighted ? 'support-success' : 'dark'}
          fontStyle="m-700"
          text={title}
        />
      )}
      <Text as="p" fontStyle="m-300" color="dark">
        {body}
      </Text>

      {isDefined(details) && !showDetails && !forceExpandMoreInfo && (
        <BreakdownButton
          text={`${trans('cancellation_policy_breakdown_more-info')}`}
          onClick={() => setShowDetails(true)}
          className="mt-m"
        />
      )}

      {isDefined(details) &&
        isDefined(deadline) &&
        (showDetails || forceExpandMoreInfo) && (
          <>
            <div className={classNames(styles.showDetailsWrapper, 'mt-m')}>
              <Flex direction="column">
                <Text fontStyle="s-700" color="dark">
                  {trans('cancellation_policy_breakdown_until')}
                </Text>
                <Text fontStyle="s-300" color="dark">
                  {deadline}
                </Text>
              </Flex>
              <Text as="p" fontStyle="s-300" color="dark">
                {details.beforeDeadline}
              </Text>

              <Divider
                dir="horizontal"
                className={classNames(styles.divider, 'mt-m mb-m')}
              />
              <Flex direction="column">
                <Text fontStyle="s-700" color="dark">
                  {trans('cancellation_policy_breakdown_from')}
                </Text>
                <Text fontStyle="s-300" color="dark">
                  {deadline}
                </Text>
              </Flex>
              <Text
                className={styles.cancellationPolicy}
                as="p"
                fontStyle="s-300"
                color="dark"
              >
                {details.afterDeadline}
              </Text>
              <Divider
                dir="horizontal"
                className={classNames(styles.divider, 'mt-m mb-m')}
              />
              <Text fontStyle="s-700" color="dark">
                {trans('cancellation_policy_breakdown_if-you-dont-come')}
              </Text>
              <Text
                className={styles.cancellationPolicy}
                as="p"
                fontStyle="s-300"
                color="dark"
              >
                {details.afterCheckIn}
              </Text>
            </div>
          </>
        )}
    </div>
  )
}
