import { CancellationPolicy } from 'src/core/Shared/domain/Policies.model'
import { Translator } from '../hooks/useTrans'

const TRANSLATE_CONTEXT_KEY = 'common:cancellation_policy_title'

export const getCancellationTitle = (
  cancellation: CancellationPolicy,
  trans: Translator,
): string => {
  if (cancellation.cancellationType === 'non-refundable') {
    return trans(`${TRANSLATE_CONTEXT_KEY}_non-refundable`)
  }

  if (cancellation.cancellationType !== 'free') {
    return trans(
      `${TRANSLATE_CONTEXT_KEY}_policy-${cancellation.relative.penalty.type}`,
      {
        penaltyValue: cancellation.relative.penalty.value,
      },
    )
  }

  return trans(`${TRANSLATE_CONTEXT_KEY}_free`)
}
