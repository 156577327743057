import { Skeleton } from 'src/ui/components'
import { Media } from 'src/ui/styles/objects/Media'

export const GuaranteePolicySkeleton = () => {
  return (
    <>
      <Media mobile>
        <div>
          <Skeleton className="mb-s" height={30} width={200} />
          <Skeleton height={30} width="100%" />
          <Skeleton height={30} width="100%" />
          <Skeleton height={30} width="100%" />
        </div>
      </Media>
      <Media tablet laptop desktop>
        <div>
          <Skeleton className="mb-s" height={30} width={200} />
          <Skeleton height={30} width={280} />
          <Skeleton height={30} width={320} />
          <Skeleton height={30} width={300} />
        </div>
      </Media>
    </>
  )
}
