import { container } from 'src/core/Shared/_di'

export function sendStartAnalyticsEvent({
  mbox,
  variant = '',
  eventName,
  experimentName,
}: {
  mbox: string
  variant?: string
  eventName: string
  experimentName: string
}) {
  container
    .resolve('logger')
    .info('Target Experiment :: startSendAnalyticsEvent', {
      mbox,
      variant,
      eventName,
    })

  const timeout = 10000
  const interval = 1000
  let elapsedTime = 0
  const analyticsInterval = setInterval(function () {
    if (typeof window.ga === 'function' && typeof window.gtag === 'function') {
      clearInterval(analyticsInterval)
      sendEventToGoogleAnalytics({ mbox, variant, eventName, experimentName })
    } else if (elapsedTime <= timeout) {
      elapsedTime += interval
    } else {
      clearInterval(analyticsInterval)
    }
  }, interval)
}

function sendEventToGoogleAnalytics({
  mbox,
  variant,
  eventName,
  experimentName,
}: {
  mbox: string
  variant: string
  eventName: string
  experimentName: string
}) {
  if (typeof window.ga !== 'function' || typeof window.gtag !== 'function') {
    container.resolve('logger').warning('ga o gtag undefined')
    return
  }

  container
    .resolve('logger')
    .info('Target experiment :: sendEventGA', { mbox, eventName, variant })

  const eventAction =
    experimentName + (eventName.length > 0 ? ' ' + eventName : '')

  window.gtag('config', 'G-0FXFK8NDBB')
  window.gtag('event', 'target', {
    event: 'adobe target',
    event_category: 'adobe target',
    event_action: eventAction,
    event_label: variant,
  })
  window.ga('create', 'UA-40201294-10', 'auto')
  window.ga('send', 'event', {
    eventCategory: 'adobe target',
    eventAction: eventAction,
    eventLabel: variant,
  })
}
