import { useCallback, useEffect, useState } from 'react'
import { getTargetOffer } from './getTargetOffer'
import { sendExperimentEvent } from './sendExperimentEvent'
import { sendStartAnalyticsEvent } from './sendStartAnalyticsEvent'
import { ContentItem } from './TargetExperiment.model'
import { container } from 'src/core/Shared/_di'
import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type Variant = undefined | string | 'CONTROL'

export type TargetExperimentConfig = {
  hasToSendStartAnalyticWhenShow?: boolean
  isExperimentLaunchedManually?: boolean
}

export const useTargetExperiment = (
  mbox: string | undefined,
  config: TargetExperimentConfig = {
    hasToSendStartAnalyticWhenShow: false,
    isExperimentLaunchedManually: false,
  },
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [variantToShow, setVariantToShow] = useState<Variant>(undefined)
  const [experimentName, setExperimentName] = useState<string | undefined>(
    undefined,
  )

  const getExperiment = async () => {
    // Aunque mbox sea obligatorio, como su valor es dinámico por variable
    // de entorno, si no está configurada la variable puede llegar vacío.
    if (isUndefined(mbox) || isEmpty(mbox)) {
      setVariantToShow('CONTROL')
      return
    }

    setIsLoading(true)
    return getTargetOffer(mbox)
      .then((content: ContentItem | undefined) => {
        if (isUndefined(content)) {
          setIsEnabled(false)
          setVariantToShow('CONTROL')
        } else {
          setIsEnabled(true)
          setVariantToShow(content.variant)
          setExperimentName(content.testName)

          if (config.hasToSendStartAnalyticWhenShow) {
            sendStartAnalyticsEvent({
              mbox: mbox,
              eventName: '',
              variant: content.variant,
              experimentName: content.testName,
            })
          }
        }
      })
      .catch((result: { status: unknown; error: unknown }) => {
        setIsEnabled(false)
        setVariantToShow('CONTROL')
        console.error('getTargetOffer ERROR', result.status, result.error)
        container.resolve('logger').error(result.error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const startSendStartAnalyticsEvent = useCallback(() => {
    if (!isEnabled) {
      return
    }

    sendStartAnalyticsEvent({
      mbox: mbox!,
      eventName: '',
      variant: variantToShow,
      experimentName: experimentName!,
    })
  }, [experimentName, isEnabled, mbox, variantToShow])

  const startSendExperimentEvent = (eventName: string) => {
    if (!isEnabled) {
      return
    }

    sendExperimentEvent({
      mbox: mbox!,
      eventName,
      variant: variantToShow,
      experimentName: experimentName!,
    })
  }

  useEffect(() => {
    if (isUndefined(mbox) || isEmpty(mbox)) {
      container
        .resolve('logger')
        .warning(
          `useTargetExperiment: mbox is not defined in Vercel environment variables`,
        )
    }
  }, [])

  useEffect(() => {
    if (
      isUndefined(mbox) ||
      isEmpty(mbox) ||
      config.isExperimentLaunchedManually
    ) {
      return
    }
    getExperiment()
  }, [])

  return {
    loadExperiment: getExperiment,
    isLoading,
    isEnabled,
    variantToShow,
    experimentName,
    sendStartAnalyticsEvent: startSendStartAnalyticsEvent,
    sendExperimentEvent: startSendExperimentEvent,
  }
}
