import { FC } from 'react'
import { Link } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  cookies,
  legalNotice,
  legalTerms,
  privacyPolicy,
} from 'src/ui/navigation/cmsLinks'
import { classNames } from 'src/ui/utils/classnames'
import styles from './Footer.module.scss'

export const Footer: FC = () => {
  const { trans } = useTrans(['common'])

  const legalNoticeUrl = legalNotice()
  const privacyPolicyUrl = privacyPolicy()
  const cookiesUrl = cookies()
  const legalTermsUrl = legalTerms()

  return (
    <footer className={classNames(styles.footer)}>
      <div className={styles.content}>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <Link variant="simple" link={legalNoticeUrl}>
              {trans('footer_legal-notice')}
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link variant="simple" link={privacyPolicyUrl}>
              {trans('footer_private-policy')}
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link variant="simple" link={cookiesUrl}>
              {trans('footer_cookies')}
            </Link>
          </li>
          <li className={styles.menuItem}>
            <Link variant="simple" link={legalTermsUrl}>
              {trans('footer_legal-terms')}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
